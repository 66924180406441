import * as Types from '../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateFileUrlMutationVariables = Types.Exact<{
  newFileUrlInput: Types.NewFileUrlInput;
}>;


export type GenerateFileUrlMutation = { __typename?: 'Mutation', generateFileUrl: string };

export type GenerateFileUploadUrlsMutationVariables = Types.Exact<{
  newFilesUploadUrlsInput: Array<Types.NewFileUploadUrlInput> | Types.NewFileUploadUrlInput;
}>;


export type GenerateFileUploadUrlsMutation = { __typename?: 'Mutation', generateFileUploadUrls: Array<{ __typename?: 'FileUploadUrl', id: string, url: string }> };


export const GenerateFileUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateFileUrl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newFileUrlInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NewFileUrlInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateFileUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newFileUrlInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newFileUrlInput"}}}]}]}}]} as unknown as DocumentNode;
export type GenerateFileUrlMutationFn = Apollo.MutationFunction<GenerateFileUrlMutation, GenerateFileUrlMutationVariables>;

/**
 * __useGenerateFileUrlMutation__
 *
 * To run a mutation, you first call `useGenerateFileUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFileUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFileUrlMutation, { data, loading, error }] = useGenerateFileUrlMutation({
 *   variables: {
 *      newFileUrlInput: // value for 'newFileUrlInput'
 *   },
 * });
 */
export function useGenerateFileUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateFileUrlMutation, GenerateFileUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateFileUrlMutation, GenerateFileUrlMutationVariables>(GenerateFileUrlDocument, options);
      }
export type GenerateFileUrlMutationHookResult = ReturnType<typeof useGenerateFileUrlMutation>;
export type GenerateFileUrlMutationResult = Apollo.MutationResult<GenerateFileUrlMutation>;
export type GenerateFileUrlMutationOptions = Apollo.BaseMutationOptions<GenerateFileUrlMutation, GenerateFileUrlMutationVariables>;
export const GenerateFileUploadUrlsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateFileUploadUrls"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newFilesUploadUrlsInput"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NewFileUploadUrlInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateFileUploadUrls"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newFilesUploadUrlsInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newFilesUploadUrlsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode;
export type GenerateFileUploadUrlsMutationFn = Apollo.MutationFunction<GenerateFileUploadUrlsMutation, GenerateFileUploadUrlsMutationVariables>;

/**
 * __useGenerateFileUploadUrlsMutation__
 *
 * To run a mutation, you first call `useGenerateFileUploadUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFileUploadUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFileUploadUrlsMutation, { data, loading, error }] = useGenerateFileUploadUrlsMutation({
 *   variables: {
 *      newFilesUploadUrlsInput: // value for 'newFilesUploadUrlsInput'
 *   },
 * });
 */
export function useGenerateFileUploadUrlsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateFileUploadUrlsMutation, GenerateFileUploadUrlsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateFileUploadUrlsMutation, GenerateFileUploadUrlsMutationVariables>(GenerateFileUploadUrlsDocument, options);
      }
export type GenerateFileUploadUrlsMutationHookResult = ReturnType<typeof useGenerateFileUploadUrlsMutation>;
export type GenerateFileUploadUrlsMutationResult = Apollo.MutationResult<GenerateFileUploadUrlsMutation>;
export type GenerateFileUploadUrlsMutationOptions = Apollo.BaseMutationOptions<GenerateFileUploadUrlsMutation, GenerateFileUploadUrlsMutationVariables>;